var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "category-main" },
    [
      _c("Breadcrumb", { attrs: { items: _vm.breadcrumb } }),
      _c("ebsn-style", {
        attrs: { target: _vm.category, path: "category_advanced.CSS_STYLE" }
      }),
      _c("category-block", {
        staticClass: "category-block category-block-1",
        attrs: {
          target: _vm.category,
          "category-id": _vm.category.categoryId,
          position: "position1"
        }
      }),
      _c(
        "v-container",
        { staticClass: "category-header" },
        [
          _c("ebsn-meta", {
            style: {
              color: _vm.$ebsn.meta(_vm.category, "category_info.COLOR")
            },
            attrs: {
              target: _vm.category,
              path: "category_info.TITLE",
              "default-value": _vm.category.name,
              tag: "h1"
            }
          }),
          _c("v-img", {
            attrs: {
              src: _vm.$ebsn.meta(_vm.category, "category_info.HEADER_IMAGE"),
              contain: "",
              onerror: "this.onerror=null;this.src='/no-icon.png'",
              width: "100%"
            }
          }),
          _c("ebsn-meta", {
            staticClass: "SUBTITLE",
            attrs: {
              target: _vm.category,
              path: "category_info.SUBTITLE",
              tag: "h3"
            },
            on: { click: _vm.clicked }
          }),
          _c("ebsn-meta", {
            staticClass: "description",
            attrs: {
              target: _vm.category,
              path: "category_info.DESCRIPTION",
              tag: "p"
            },
            on: { click: _vm.clicked }
          })
        ],
        1
      ),
      _c(
        "v-container",
        [
          _c("CategoryMainSlider", {
            key: _vm.category.categoryId,
            attrs: {
              categories: _vm.category.children,
              paginationClass: "subcategory"
            }
          })
        ],
        1
      ),
      _c("category-block", {
        staticClass: "category-block category-block-2",
        attrs: {
          target: _vm.category,
          "category-id": _vm.category.categoryId,
          position: "position2"
        }
      }),
      _c("category-block", {
        staticClass: "category-block category-block-3",
        attrs: {
          target: _vm.category,
          "category-id": _vm.category.categoryId,
          position: "position3"
        }
      }),
      _c(
        "v-container",
        [
          _c("ebsn-meta", {
            attrs: {
              target: _vm.category,
              path: "category_info.FOOTER_DESCRIPTION",
              tag: "div"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }